import React, { useState } from "react";
import dayjs from "dayjs";
import { Button, Form } from "react-bootstrap";

import ActionModal from "../../../components/ActionModal/ActionModal";
import ChangeExpiryModal from "./ChangeExpiryModal";

import "./Subscriptions.scss";

const Subscriptions = (props) => {
  const {
    planName,
    monthlyPrice,
    updated_at,
    plan_expire,
    auto_renew_status,
    first_billing_date,
    latest_billing_date,
    next_billing_date,
    user_level,
    payment,
    price,
    original_subscription_id,
    is_expired,
    onSwitchSubscription,
    onExpiryDateChange,
    onSubmitCoupon
  } = props;

  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [showExpiryModal, setShowExpiryModal] = useState(false);
  const [couponCode, setCouponCode] = useState('');
  const [submittingCoupon, setSubmittingCoupon] = useState(false);

  const handleSwitchSubscription = (ev) => {
    const checked = ev.target.checked;
    setShowSubscriptionModal(true);
  };

  const hideSubscriptionModal = () => {
    setShowSubscriptionModal(false);
  }

  const handleUpdateSubscription = () => {
    onSwitchSubscription(hideSubscriptionModal);
  };

  const handleApplyCoupon = () => {
    if (typeof onSubmitCoupon === "function") {
      setSubmittingCoupon(true);
      onSubmitCoupon({
        coupoun: couponCode
      }, () => {
        setSubmittingCoupon(false);
      });
    }
  };

  return (
    <div className="member-subscription">
      {
        user_level ? (
          <>
            <div className="row">
              <div className="col1 label">Status</div>
              <div className="col2">
                <Form>
                  <Form.Check
                    checked={auto_renew_status}
                    type="switch"
                    id="subscription-switch"
                    disabled={auto_renew_status === 0}
                    onChange={handleSwitchSubscription}
                  />
                </Form>
              </div>
            </div>
            <div className="row">
              <div className="col1 label">Plan</div>
              <div className="col2">({planName}) - ${monthlyPrice}</div>
              <div className="col3">{updated_at &&  `updated ${dayjs(updated_at).format('DD/MM/YYYY')}`}</div>
            </div>
            <div className="row">
              <div className="col1 label">Plan Expire Date</div>
              <div className="col2">
                {plan_expire && dayjs(plan_expire).format('DD/MM/YYYY')}
                { is_expired === 1 && (<span className="member-status membership-expired">(Membership expired)</span>) }
                { is_expired === 0 && (<span className="member-status">(Membership expires on this date)</span>) }
              </div>
              <div className="col3">
                <Button variant="success" onClick={() => setShowExpiryModal(true)}>Change</Button>
              </div>
            </div>
            <div className="row">
              <div className="col1 label">First Billing Date</div>
              <div className="col2">{first_billing_date && dayjs(first_billing_date).format('DD/MM/YYYY')}</div>
            </div>
            <div className="row">
              <div className="col1 label">Latest Billing Date</div>
              <div className="col2">{latest_billing_date && dayjs(latest_billing_date).format('DD/MM/YYYY')}</div>
            </div>
            <div className="row">
              <div className="col1 label">Next Billing Date</div>
              <div className="col2">{next_billing_date && dayjs(next_billing_date).format('DD/MM/YYYY')}</div>
            </div>
            <div className="row">
              <div className="col1 label">Price</div>
              <div className="col2">{typeof price === 'number' && `$${price}`}</div>
            </div>
            <div className="row">
              <div className="col1 label">User Level</div>
              <div className="col2">{user_level}</div>
            </div>
            <div className="row">
              <div className="col1 label">Payment</div>
              <div className="col2">{payment}</div>
            </div>
            <div className="row">
              <div className="col1 label">Original Subscription ID</div>
              <div className="col2">{original_subscription_id}</div>
            </div>
            

            <ActionModal
              showModal={showSubscriptionModal}
              title="Change Subscription Status"
              actionText={ auto_renew_status ? 'Unsubscribe' : 'Subscribe'}
              onClose={() =>  setShowSubscriptionModal(false)}
              onAction={handleUpdateSubscription}>
                Do you want to { auto_renew_status ? 'unsubscribe' : 'subscribe'}?
            </ActionModal>
            <ChangeExpiryModal
              date={plan_expire}
              showModal={showExpiryModal}
              onClose={() => setShowExpiryModal(false)}
              onExpiryDateChange={onExpiryDateChange}
            />
          </>
        ) : (
          <h4 class="text-align-center">
            No subscriptions found.
          </h4>
        )}
        <div className="row">
              <div className="full-width-field">
                <Form.Control
                  className="coupon-field"
                  name="coupon"
                  placeholder="Coupon Code"
                  onChange={(ev) => setCouponCode(ev.target.value)}
                />
                <Button
                  type="submit"
                  disabled={submittingCoupon}
                  onClick={handleApplyCoupon}>
                    Apply Coupon
                </Button>
              </div>
        </div>
    </div>
  );
};

export default Subscriptions;
