import React, { Component } from "react";
import "./Footer.scss";

class Footer extends Component {
  render() {
    return (
      <div className="kt-login-v2__footer flex-justify-center">
        <div className="elements-part flex-align-center">
          <div className="kt-login-v2__info">
            <a href="/">©2021 Gammakite LLC. All right Reserved.</a>
            <div className="support">
              <a href="/privacy.html" className="kt-font-bran">
                Privacy
              </a>
              <a href="/terms.html" className="kt-font-bran">
                Terms of Service
              </a>
              <a href="/refund-policy.html" className="kt-font-bran">
                Refund Policy
              </a>
              <a href="mailto:support@fullchinese.com" className="kt-font-bran">
                Support
              </a>
            </div>
          </div>
          <div className="kt-login-v2__link">
            <img src="/img/iconmonstr-facebook-4.png" alt="facebook" />
            <img src="/img/iconmonstr-instagram-11.png" alt="instragram" />
            <img src="/img/iconmonstr-linkedin-3.png" alt="linkedin" />
            <img src="/img/iconmonstr-youtube-6.png" alt="youtube" />
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
