import React, { Component } from "react";
import "./Footer.scss";

class TermsOfService extends Component {
  render() {
    return (
      <div className="terms-of-service">
        <div style={{ height: 340 }}>
          <img src="/img/refund-policy.jpg" className="img-fluid w-100" alt="ima" style={{ height: 320 }} />
          <button
            className="goSignIn position-absolute"
            onClick={() => {
              this.props.history.push("/");
            }}
          >
            Back
          </button>
        </div>
        <div class="container">
          <div class="space-60"></div>
          <div class="row">
            <div class="col-md-12 mb-5">
              <h3>1. Refund Policy</h3>
              <hr />
              <p>
                Fullchinese Pro is a Gammkite, Inc. Product. We provide subscriptions for the plans in Apps. All the
                plans could be subscribed though 3 payment methods with free 14 days trial days:
              </p>
              <ul class="list">
                <li>
                  <i class="ion-checkmark"></i>Google Payment
                </li>
                <li>
                  <i class="ion-checkmark"></i>Apple Payment
                </li>
                <li>
                  <i class="ion-checkmark"></i>Web Payment
                </li>
              </ul>
              <p>
                Since we provide the 14 trial days, we will not refund for the already billed subscriptions. Once you
                canceled your subscriptions, the payment method you provided won't be charged again for the next billing
                subscription periods. Your membership will continue until this subscription period ends.
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 mb-5">
              <h3>2. Unsubscribe method</h3>
              <hr />
              <p className="sub-title">If you subscribe directly through FullChinese.com </p>
              <p>Note: If you've subscribed through FullChinese, you can only cancel via FullChinese’s website.</p>
              <p>
                Go to{" "}
                <a href="https://www.fullchinese.com/" style={{ marginLeft: 5, textDecoration: "underline" }}>
                  www.fullchinese.com
                </a>{" "}
                and Log in , you will see the profile page. Click on Subscriptions tab , and click the Cancel
                Subscriptions Button You’ll see the subscription status canceled once success.
              </p>
              <p className="sub-title">If you subscribe via the Apple App Store</p>
              <p>
                step 1: Go to Settings on your iPhone or iPad <br />
                step 2: Tap iTunes & App Store <br />
                step 3: Tap your Apple ID <br />
                step 4: Tap View Apple ID and sign in if necessary <br />
                step 5: Tap Subscriptions <br />
                step 6: Tap Fullchinese subscription <br />
                step 7: Select Cancel
              </p>
              <p className="sub-title">If you subscribe via the Google Play Store</p>
              <p>
                step 1: Open the Google Play Store Confirm that you are signed in to the correct Google account <br />
                step 2: Tap Menu Subscriptions Select Fullchinese subscription <br />
                step 3: Tap Cancel subscription
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 mb-5">
              <h3>3. Contact Us</h3>
              <hr />
              <p>
                Address: Gammakite, Inc, 405 Concord Avenue, Belmont MA 02478 <br />
                Email: <a href="mailto:support@fullchinese.com">support@fullchinese.com</a>
                <br />
                Phone: 617-362-0375
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 mb-5">
              <h3>CopyRight @gammakite, inc.</h3>
            </div>
          </div>
          <div class="space-60"></div>
        </div>
      </div>
    );
  }
}

export default TermsOfService;
