import React, { Component } from "react";
import "./Footer.scss";

class TermsOfService extends Component {
  render() {
    return (
      <div className="terms-of-service">
        <div style={{ height: 340 }}>
          <img src="/img/privacy.jpg" className="img-fluid w-100" alt="ima" style={{ height: 320 }} />
          <button
            className="goSignIn position-absolute"
            onClick={() => {
              this.props.history.push("/");
            }}
          >
            Back
          </button>
          <div className="centered position-absolute text-white pt-5" style={{ top: "15%" }}>
            <h1 className="text-uppercase">Fullchinese Hosted Privacy Policy</h1>
          </div>
        </div>
        <div class="container">
          <div class="space-60"></div>
          <div class="row">
            <div class="col-md-12 mb-5">
              <h3>1. General</h3>
              <hr />
              <p>
                At Gammakite , (the company that develops the FullChinese apps and website) we care about your personal
                data, so we have prepared this Privacy Policy to explain how we collect, use and share it.
              </p>
              <p>
                This Privacy Policy <b>(“ Privacy Policy ”)</b> details the personal data Gammakite , Inc. (
                <b>“ Gammakite ”, “ we ”, “ us ”</b> or
                <b>“ our ”</b>) receives about you, how we process it and your rights and obligations in relation to
                your personal data. Gammakite , Inc., a Delaware Company, is the data controller for the purposes of the
                General Data Protection Regulation <b>(“ GDPR ”)</b>
                and any relevant local legislation <b>(“ Data Protection Laws ”)</b>.
              </p>
              <p>
                By using or accessing the Service, you agree to the terms of this Privacy Policy. Capitalized terms not
                defined here have the meanings set forth in the terms and conditions (the{" "}
                <b>“ Terms and Conditions ”</b>), located at{" "}
                <a href="https://www.fullchinese.com/terms.html" style={{ marginLeft: 5, textDecoration: "underline" }}>
                  www.fullchinese.com/terms.html
                </a>
                . We may update our Privacy Policy to reflect changes to our informatio n practices. If we do this and
                the changes are material, we will post a notice that we have made changes to this Privacy Policy on the
                Website for at least 7 days before the changes are made, and we will indicate the date these terms were
                last revised at t he bottom of the Privacy Policy. Any revisions to this Privacy Policy will become
                effective at the end of that 7 - day period.
              </p>
              <p>
                If you are an employee, worker or contractor of Gammakite , the information about how we handle your
                personal information is availab le in the Gammakite internal knowledge base.
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 mb-5">
              <h3>2. Information We Collect</h3>
              <hr />
              <p>This Privacy Policy explains how we collect, use and share your personal data.</p>
              <p className="sub-title">Information you provide</p>
              <p>
                Through the registration process, you will provide us with your name, e - mail address (or parent’s e -
                mail address ), language level . You will also provide us with your payment transaction information if
                you choose to pay for Gammakite services.
              </p>
              <p>
                We use cookies to record current session information, but do not use permanent cookies. You are required
                to re-login to your source control system account after a certain period of time has elapsed to protect
                you against others accidentally accessing your account contents.
              </p>
              <p className="sub-title">General Activity Data</p>
              <p>
                When you use the Service, you will submit information and content to your profile. We will also generate
                data about your use of our Services including your engagement in educational activities on the Service
                (“ General Activity Data ”). We also collect technical data about how you interact with our Services.
              </p>
              <p className="sub-title">Low Level Activity Data</p>
              <p>
                In the future, we may give you, in an opt-in manner, the option to turn on a low-level activity tracking
                for a limited amount of time (not to exceed one day). This activity tracking could record your
                interaction with the application such as how many words you flag, how many dictionary entries you
                consult, for how long. This low-level activity data is collected for the purpose of improving the
                application and the language learning method. Your opt-in button, given inside the app, can also be used
                to withdra w your consent at any time. This data will not be shared with any third party without your
                explicit prior consent.
              </p>
              <p>
                When you use the Service, you will submit information and content to your profile. We will also generate
                data about your use of our Services including your engagement in educational activities on the Service
                (“ General Activity Data ”). We also collect technical data about how you interact with our Services.
              </p>
              <p className="sub-title">Learning Data</p>
              <p>
                Learning data is the data that’s generated when you flag words, sentences, modify dictionary entries,
                upload texts or text samples for your own personal study , perform quizzes . This data is stored such
                that you can keep personalizing your language learning environment. This data is not shared with anybody
                and is also not used for any other purpose without your explicit consent. This explicit consent can be
                withdrawn at any point.
              </p>
              <p>
                If you use the app as part of a class, you will have the option of sharing certain type of content with
                your teacher but only after your explicit consent. This includes which word you flag or which
                translation you enter for some specific language segments. This explicit consent can also be withdrawn
                at any point.
              </p>
              <p className="sub-title">Research and Development Data</p>
              <p>
                We may contact you to participate in product research activities such as interviews or surveys . When
                you participate in these research activities, the information you provide will be used to test, improve,
                and develop our products. We could ask you to record the video, audio and text transcriptions of this
                feedback together with any additional contact information you provide and will retain this data for two
                years. You can request at any point that this data be discarded.
              </p>
              <p>
                Please contact us at <a href="mailto:info@gammakite.com">info@gammakite.com</a> to:
              </p>
              <ul class="list">
                <li>
                  <i class="ion-checkmark"></i>Request more information about the research activities you have
                  volunteered for.
                </li>
                <li>
                  <i class="ion-checkmark"></i>Opt-out of being contacted for research activities.
                </li>
                <li>
                  <i class="ion-checkmark"></i>Request a copy of any research related data you have provided to us.
                </li>
              </ul>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 mb-5">
              <h3>3. Cookies</h3>
              <hr />
              <p>
                When you enter the Website, we collect your browser type and your IP address (a unique address that
                identifies your computer on the Internet). When you use our mobile Application, we collect your device
                type, your device ID, and your IP address.
              </p>
              <p>
                In addition, we store certain information from yo ur browser using "cookies." A cookie is a piece of
                data stored on the user's computer tied to information about the user. We use session ID cookies to
                confirm that users are logged in. If you do not want information collected through the use of cookies,
                th ere is a simple procedure in most browsers that allows you to deny or accept the cookie feature;
                however, you should note that cookies may be necessary to provide you certain features available on the
                Website (e.g., customized delivery of information).
              </p>
              <p>
                You can find more information about cookies and how to manage them at{" "}
                <a href="http://www.allaboutcookies.org/" style={{ marginLeft: 5, textDecoration: "underline" }}>
                  http://www.allaboutcookies.org/
                </a>
                .
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 mb-5">
              <h3>4. Information Obtained by Third Parties</h3>
              <hr />
              <p>
                To support and enhance the Service, we work with a variety of third-party advertising networks,
                marketing analytics service providers and website analysis firms, such as Google, Facebook, Oath, and
                Unity (collectively, <b>“ Third Party Advertising Service Providers ”</b>). These Third-Party
                Advertising Service Providers collect and us e personal data about your visits to and use of the Website
                and mobile Application, as well as other websites in order to serve you with advertisements and content
                tailored to meet your preferences and likely interests or better understand ad effectiveness . Aggregate
                demographic and interest data will also be used for market research purposes.
              </p>
              <p>
                We use Google Analytics which is a web analytics tool that helps us understand how users engage with the
                Website. Like many services, Google Analytics uses first-party cookies to track user interactions as in
                our case, where they are used to collect information about how users use our site. This information is
                used to compile reports and to help us improve our Website. The reports disclose website trends without
                identifying individual visitors. You can opt out of Google Analytics without affecting how you visit our
                site – for more information on opting out of being tracked by Google Analytics across all websites you
                use, visit this Google page:{" "}
                <a
                  href="https://tools.google.com/dlpage/gaoptout"
                  style={{ marginLeft: 5, textDecoration: "underline" }}
                >
                  https://tools.google.com/dlpage/gaoptout
                </a>
              </p>
              <p>
                You can opt - out of receiving personalized ads from advertisers and ad networks that are members of the
                Network Advertising Initiative (“NAI”) or who follow the DAA Self - Regulatory Pr inciples for Online
                Behavioral Advertising using their respective opt - out tools. The NAI's opt - out tool can be found
                here{" "}
                <a
                  href="http://www.networkadvertising.org/choices/"
                  style={{ marginLeft: 5, textDecoration: "underline" }}
                >
                  http://www.networkadvertising.org/choices/
                </a>{" "}
                and the DAA's opt out tool can be found here
                <a href="http://www.aboutads.info/choices/" style={{ marginLeft: 5, textDecoration: "underline" }}>
                  http://www.aboutads.info/choices/
                </a>
              </p>
              <p>
                In addition, your mobile devices may offer settings that enable you to make choices about the
                collection, use, or transfer of mobile app information for online behavioral advertising (for example,
                Apple iOS’ Advertising ID and Google Android’s Advertising ID). Please note that opting out does not
                prevent the display of all advertisements to you.
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 mb-5">
              <h3>5. Use of information obtained by Gammakite</h3>
              <hr />
              <p>
                Gammakite may occasionally send you service related and product change announcements through the general
                operation of the Service. We will send you reminder notifications to support the Gammakite teaching
                methodology by reinforcing the learning cadence and help maintain your learning streak. We process your
                data to help provide you with the Service and offer personalized features, to understand and improve our
                Service and to keep our Service safe and secure.
              </p>
              <p>
                Gammakite may use or share anonymous data collected through the Servi ce, including Activity Data
                without limitation. As a business, it is critical that we perform our contract with you with the best
                service possible, and it is in our legitimate interests to perform these processing functions and to
                enable service e - mails by default to keep your data secure and provide our Service. You may opt out of
                any non - essential service e - mails at any time.
              </p>
              <p>
                We may also use your contact information to send you notifications regarding new services, offers and
                promotions offered by Gammakite if you affirmatively consent to receive such communications.
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 mb-5">
              <h3>6. Sharing your personal data with third parties</h3>
              <hr />
              <p>
                Gammakite shares your personal data only when it is necessary to offer the Service, legally required, or
                permitted by you.
              </p>
              <p>
                We will provide personal data to hosting providers such as Amazon Web Services, search engine providers
                such as Google, analytics providers such as Crashlytics, and support providers such as ZenDesk.
              </p>
              <p>
                These data processors help us bring you the Service. For example, we may share your information in order
                to detect where or how you encountered a bug when using our mobile application. In connection with these
                operations, our service providers will have access to personal data for a limited time. When we utilize
                service providers for processing any personal data, we implement contractual protections limiting the
                use of that personal data to the provision of services to Gammakite.
              </p>
              <p>
                We will be required to access and disclose personal data in response to lawful requests, such as su
                bpoenas or court orders, or in compliance with applicable laws. Additionally, we will access and share
                account or other personal data when we believe it is necessary to comply with law, to protect our
                interests or property, to prevent fraud or other illega l activity perpetrated through the Service or
                using the Gammakite name, or to prevent imminent harm. This will include accessing and sharing personal
                data with other companies, lawyers, agents or government agencies.
              </p>
              <p>
                If the ownership of all or substantially all of the Gammakite business, or individual business units or
                assets owned by Gammakite that are related to the Service, were to change, your personal data will be
                transferred to the new owner. In any such transfer of information, your personal data would remain
                subject to this section.
              </p>
              <p>
                Gammakite will share aggregate or anonymous data collected through the Service, including Activity Data,
                for purposes such as understanding or improving the service.
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 mb-5">
              <h3>7. Data subject rights and data retention</h3>
              <hr />
              <p>
                You can manage your account settings at{" "}
                <a
                  href="https://www.duolingo.com/settings/account"
                  style={{ marginLeft: 5, textDecoration: "underline" }}
                >
                  https://www.Gammakite.com/settings/account
                </a>{" "}
                to update, amend, and correct your information.
              </p>
              <p>
                You also have the following rights in relation to the personal data we hold about you, unless provided
                otherwise by local law:
              </p>
              <ul class="list">
                <li>
                  <i class="ion-checkmark"></i>To request access to, or erasure of, the personal data we hold about you.
                </li>
                <li>
                  <i class="ion-checkmark"></i>To request us to restrict the processing of the personal data we hold
                  about you.
                </li>
                <li>
                  <i class="ion-checkmark"></i>To object to us processing personal data relating to you
                </li>
                <li>
                  <i class="ion-checkmark"></i>Where you have given us consent to process your personal data, you have
                  the right to withdraw that consent at any time.
                </li>
                <li>
                  <i class="ion-checkmark"></i>To export the personal data you have provided to Gammakite in a format
                  that can be transferred electronically to a third party.
                </li>
                <li>
                  <i class="ion-checkmark"></i>To del ete your account with Gammakite by following the instructions
                  available through the Service.
                </li>
              </ul>
              <p>
                Gammakite will retain your data until your account is deleted, after which point we will retain
                anonymous data collected through the Service, including Activity Data, which may be used by Gammakite
                and shared with third parties in any manner.
              </p>
              <p>
                Please note that some of these rights are not absolute. In some cases, we may refuse a request to
                exercise particular rights if complying with it meant that we are no longer able to meet our contractual
                obligation to provide you with particular products and services. We will keep you informed as to the
                actions that we can take when you make your request.
              </p>
              <p>
                You may also have the right to make a GDPR complaint to the relevant Supervisory Authority. A list of
                Supervisory Authorities is available here:{" "}
                <a
                  href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                  style={{ marginLeft: 5, textDecoration: "underline" }}
                >
                  http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
                </a>
                . If you need further assistance regarding your rights, please contact us using the contact information
                provided below and we will consider your request in accordance with applicable law. In some cases our
                ability to uphold these rights for you may depend upon our obligations to process personal information
                for security, safety, fraud prevention reasons , compliance with regulatory or legal requirements, or
                because processing is necessary to deliver the services you have requested. Where this is the case, we
                will inform you of specific details in response to your request.
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 mb-5">
              <h3>8. Third party websites and links</h3>
              <hr />
              <p>
                Please note that you may have cookies placed on your computer by third party websites that refer you to
                our Service. Although we do not share your personal data with these third party websites unless it is
                reasonably necessary to offer the Service, they may be able to link certain non - personally
                identifiable information we transfer to them with personal data they previously collected from you.
                Please review the privacy policies of each website you visit to better understand their privacy
                practices. In addition, Gammakite would like to inform you that anytime you click on links (including
                advertising banners), which take you to third party websites, you will be subject to the third parties’
                privacy policies.
              </p>
              <p>
                Our Services contain links to other sites operated by third parties. Gammakite does not control such
                other sites and is not responsible for their content, their privacy policies, or their use of personal
                data, including any personal or financial information collected by our third party payment processor to
                p rocess payments for in - app purchases. Gammakite ’s inclusion of such links does not imply any
                endorsement of the content on such sites or of their owners or operators except as disclosed on the
                Services. Any information submitted by you directly to these third parties is subject to that third
                party’s privacy policy.
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 mb-5">
              <h3>9. California privacy rights</h3>
              <hr />
              <p>Residents of California have the right to:</p>
              <ul class="list">
                <li>
                  <i class="ion-checkmark"></i>Know what personal information we collect about you and from where we
                  collect it.
                </li>
                <li>
                  <i class="ion-checkmark"></i>Access a copy of the personal information we hold about you.
                </li>
                <li>
                  <i class="ion-checkmark"></i>Understand how we process your personal information and how it may be
                  shared.
                </li>
                <li>
                  <i class="ion-checkmark"></i>Opt out of having your personal information shared with certain third
                  parties.
                </li>
                <li style={{ textDecoration: "underline" }}>
                  <i class="ion-checkmark"></i>Request we delete your Gammakite account and personal information
                </li>
              </ul>
              <p>
                To make such a request, please send an email to{" "}
                <a href="mailto:privacy@gammakite.com">privacy@gammakite.com</a>
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 mb-5">
              <h3>10. EU - U.S. Privacy Shield And Swiss - U.S. Privacy Shield</h3>
              <hr />
              <p>
                Gammakite complies with the EU - US Privacy Shield Framework and the Swiss - US Privacy Shield Framework
                as set forth by the US Department of Commerce regarding the collection, use, and retention of personal
                information from European Union member countries (including I celand, Liechtenstein, and Norway) and
                Switzerland transferred to the United States pursuant to Privacy Shield. Gammakite has certified that it
                adheres to the Privacy Shield Principles with respect to such data. If there is any conflict between the
                policies in this privacy policy and data subject rights under the Privacy Shield Principles, the Privacy
                Shield Principles shall govern. To learn more about the Privacy Shield program, and to view our
                certification page, please visit
                <a href="https://www.privacyshield.gov/" style={{ marginLeft: 5, textDecoration: "underline" }}>
                  https://www.privacyshield.gov/
                </a>
              </p>
              <p>
                Pursuant to the Privacy Shield Frameworks, EU and Swiss individuals have the right to obtain our
                confirmation of whether we maintain personal information relating to you in the United States. Upon
                request, we will provide you with access to the personal information that we hold about you. You may
                also may correct, amend, or delete the personal information we hold about you. An individual who seeks
                access, or who seeks to correct, amend, or delete inaccurate data transferred to the United States under
                Privacy Shield, should direct their query to{" "}
                <a href="mailto:privacy@gammakite.com">privacy@gammakite.com</a> . If requested to remove data, we will
                respond within a reasonable timeframe.
              </p>
              <p>
                We will provide an individual opt - out choice, or opt - in for sensitive data, before we share your
                data with third parties other than our agents, or before we use it for a purpose other than which it was
                originally collected or subsequently authorized. To request to limit the use and disclosure of your
                personal information, please submit a written request to{" "}
                <a href="mailto:privacy@gammakite.com">privacy@gammakite.com</a>
              </p>
              <p>
                Gammakite ’s accountability for personal data that it receives in the United States under the Privacy
                Shield and subsequently transfers to a third party is described in the Privacy Shield Principles. In
                particular, Gammakite remains responsible and liable under the Privacy Shield Principles if third -
                party agents that it engages to process the personal data on its behalf do so in a manner inconsistent
                with the Principles, unless Gammakite proves that it is not responsible for the event giving rise to the
                damage.
              </p>
              <p>
                With respect to personal data received or transferred pursuant to the Privacy Shield Frameworks, Gammaki
                te is subject to the regulatory enforcement powers of the U.S. Federal Trade Commission. In certain
                situations, Gammakite may be required to disclose personal data in response to lawful requests by public
                authorities, including to meet national security or law enforcement requirements.
              </p>
              <p>
                In compliance with the EU - US and Swiss - US Privacy Shield Principles, Gammakite commits to resolve
                complaints about your privacy and our collection or use of your personal data.
              </p>
              <p>
                Gammakite has further committed to refer unresolved privacy complaints under the Privacy Shield
                Principles to an independent dispute resolution mechanism, the BBB EU PRIVACY SHIELD, operated by the
                Council of Better Business Bureaus. If you do not receive timely acknowledgement of your complaint, or
                if your complaint is not satisfactorily addressed, please visit
                <a
                  href="https://www.bbb.org/EU-privacy-shield/for-eu-consumers"
                  style={{ marginLeft: 5, textDecoration: "underline" }}
                >
                  https://www.bbb.org/EU-privacy-shield/for-eu-consumers
                </a>{" "}
                for more information and to file a complaint. The services of BBB EU PRIVACY SHIELD are provided at no
                cost to you.
              </p>
              <p>
                If your complaint involves human resources data transferred to the United States from the EU and/or
                Switzerland in the context of the employment relationship, and Gammakite does not address it
                satisfactorily, Gammakite commits to cooperate with the panel established by the EU data protection
                authorities (DPA Panel) and/or the Swiss Federal Data Protection and Information Commissioner, as
                applicable and to comply with the a dvice given by the DPA panel and/or Commissioner, as applicable with
                regard to such human resources data. To pursue an unresolved human resources complaint, you should
                contact the state or national data protection or labor authority in the appropriate juri sdiction.
                Complaints related to human resources data should not be addressed to the BBB EU PRIVACY SHIELD.
              </p>
              <p>
                Contact details for the EU data protection authorities can be found at
                <a
                  href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                  style={{ marginLeft: 5, textDecoration: "underline" }}
                >
                  http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
                </a>
              </p>
              <p>
                If your Privacy Shield complaint cannot be resolved through the above channels, under certain
                conditions, you may invoke binding arbitration for some residual claims not resolved by other redress
                mechanisms. See Privacy Shield Annex 1 at
                <a
                  href="https://www.privacyshield.gov/article?id=ANNEX-I-introduction"
                  style={{ marginLeft: 5, textDecoration: "underline" }}
                >
                  https://www.privacyshield.gov/article?id=ANNEX-I-introduction
                </a>
                .
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 mb-5">
              <h3>11. Children under age of digital consent</h3>
              <hr />
              <p>
                Gamm akite provides some features and services intended for use by children. Gammakite uses age - gating
                to restrict what services are available to children depending on the age of the user and the applicable
                laws of their country. For example, a 15 year old child in Germany will require parental consent to use
                the Service, whereas a 12 year old child in the United States of America would receive a restricted
                service but not require parental consent.
              </p>
              <p className="sub-title">Children within the European Union</p>
              <p>
                Each European Union (EU) member state is permitted to legally define its own digital age of consent
                between 13 and 16. Gammakite checks the age and country of the user to determine if the user is able to
                consent to the Service. If consent of a parent/guard ian is required, the user will input the e - mail
                address of an adult who will receive a request to approve the child’s account registration. In the
                absence of a country specific Act of Law, the age of consent will be set at 16.
              </p>
              <p>
                These EU child users will receive the following service restrictions: they will not be able to use the
                service until their parent/guardian has provided consent for the child to do so. After the
                parent/guardian has given consent, they child will be allowed to access the full Gammakite service.
              </p>
              <p>
                Where Gammakite does make features and services available to children, we recognize that collecting
                personal data from children requires further privacy protections. Accordingly, Gammakite will: (1)
                notify parents or guardians about Gammakite ’s privacy and information practices regarding children,
                including what personal data we collection and how we use, share, and protect that personal data; (2)
                limit our collection of personal data to ensure that we collect no more than is necessary to provide
                Gammakite’s services; (3) obtain parental consent before collecting personal data from children; and (4)
                allow parents to request that Gammakite change or delete personal data about their children.
              </p>
              <p className="sub-title">Children outside of the European Union</p>
              <p>
                If a child of under 13 years from outside the EU registers for the Gammakite service, upon registration
                the child will provide an adult’s e - mail address, to whom Gammakite sends a notification.
              </p>
              <p>These child users will receive the following service restrictions:</p>
              <ul class="list">
                <li>
                  <i class="ion-checkmark"></i>Instead of any Personally Identifiable Information about the child, we
                  will only store the e - mail address of their parent/guardian.
                </li>
                <li>
                  <i class="ion-checkmark"></i>Only family friendly advertisements are shown and these ads do not do
                  behavioral tracking.
                </li>
                <li>
                  <i class="ion-checkmark"></i>Mature words such as "beer" and "wine" are disabled.
                </li>
                <li>
                  <i class="ion-checkmark"></i>Social features are disabled, e.g. profiles, forums, clubs, etc.
                </li>
                <li>
                  <i class="ion-checkmark"></i>They can not enter profile information such as a profile picture or a
                  bio.
                </li>
              </ul>
              <p>
                At the point when the user is known to have reached the age of 13, the account will automatically be
                transitioned to be a normal non - restricted account.
              </p>
              <p>
                At all times Gammakite does not knowingly collect personal information from children in connection with
                the Service. If we discover that we have unknowingly collected personal information from these children,
                we will delete it. If you believe we have collected personal information from a child, please contact us
                at <a href="mailto:privacy@gammakite.com">privacy@gammakite.com</a>
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 mb-5">
              <h3>12. Links</h3>
              <hr />
              <p>
                The Service may contain links to other websites. We are not responsible for the privacy practices of
                other websites. We encourage users to be aware when they leave the Service to read the privacy
                statements of other websites that collect personally identifiable information. This Privacy Policy
                applies only to information collected by Gammakite via the Service.
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 mb-5">
              <h3>13. Information security</h3>
              <hr />
              <p>
                Gammakite has implemented administrative and technical safeguards it believes are appropriate to protect
                the confidentiality, integrity and availability of your personal data, Testing ID, User Photo, access
                credentials, and Gammakite English Test results. However, given sufficient resources, a determined
                attacker could defeat those safeguards and may, as a result, gain access to th e data we seek to
                protect.
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 mb-5">
              <h3>14. Do Not Track</h3>
              <hr />
              <p>The Service is not designed to respond to “do not track” signals sent by some browsers.</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 mb-5">
              <h3>15. Contact Us</h3>
              <hr />
              <p style={{ marginBottom: 30 }}>Address:</p>
              <p>Gammakite , Inc.</p>
              <p style={{ marginTop: -15 }}>405 Concord Ave</p>
              <p>PO Box 468</p>
              <p style={{ marginTop: -15 }}>Belmont , MA 02478</p>
              <p style={{ marginTop: 30 }}>
                For all data privacy inquiries and any questions or concerns you have about this Privacy Policy, please
                contact our Data Protection Officer at <a href="mailto:privacy@gammakite.com">privacy@gammakite.com</a>
              </p>
              <p>
                For all support inquiries, please contact{" "}
                <a href="mailto:support@gammakite.com">support@gammakite.com</a>
              </p>
              <p></p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 mb-5">
              <h3>Last revised on 20 th July, 2020</h3>
            </div>
          </div>
          <div class="space-60"></div>
        </div>
      </div>
    );
  }
}

export default TermsOfService;
